import {Injectable} from '@angular/core';

type Options = {
  daysToSave?: number;
  path?: string;
  version?: string;
};

type Primitive = bigint | boolean | null | number | string | undefined;

type JSONValue = Primitive | JSONObject | JSONArray;

export interface JSONObject {
  [key: string]: JSONValue;
}

type JSONArray = Array<JSONValue>;

type SavedData = {
  value: JSONValue;
  version: string;
  expires: number;
};

const ROOT_KEY = 'terrific-live';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private terrificLiveDBObject: Record<string, JSONValue> = {};
  private terrificLiveDBObjectInSyncWithLocalStorage: Record<string, JSONValue> = {};

  private setAtPath(): void;
  private setAtPath(path: string, value: any): void;
  private setAtPath(path?: string, value?: any) {
    if (!path && !value) {
      value = this.terrificLiveDBObject;
      try {
        localStorage.setItem(ROOT_KEY, JSON.stringify(value));
        this.terrificLiveDBObjectInSyncWithLocalStorage = value;
      } catch (error) {
        // we don't care if it fails, data will be saved on the this object
      }
      return;
    }
    if (!path) throw new Error('path is required');
    setAtPath(this.terrificLiveDBObject, path, value);
    try {
      localStorage.setItem(ROOT_KEY, JSON.stringify(this.terrificLiveDBObject));
      setAtPath(this.terrificLiveDBObjectInSyncWithLocalStorage, path, value);
    } catch (error) {
      // we don't care if it fails, data will be saved on the this object
    }
  }
  /**
   * This method will set the value in the one of the following ways:
   * 1. if localStorage is available, will save the data in the localStorage
   * 2. if localStorage is not available, will save the data in memory
   *
   * all the data will be saved as JSON.stringify(value)
   * all the data will be saved with the following structure:
   * {
   *  value: JSON.stringify(value),
   *  version: '1.0.0',
   *  expires: now.getTime() + (daysToSave ?? 1) * 24 * 60 * 60 * 1000,
   * }
   * in the object of terrifc-live key
   *
   * @example
   * ``` typescript
   * localStorageService.setItem('userName', 'name', {daysToSave: 1});
   * localStorageService.setItem(session.id, {reminded: true}, {daysToSave: 1, path: 'sessionReminders'});
   * ```
   *
   * @param value any type of object, self reference will cause an error
   * @param key not required, if not provided, will use the root key/path
   * @param options optional, default values are: {daysToSave: 0, path: '/', version: '1.0.0'}
   */
  setItem(key: string, value: JSONValue, options: Options = {}) {
    const {daysToSave, path, version} = {daysToSave: 0, path: '/', version: '1.0.0', ...options};
    if (value === undefined) return this.removeItem(key, path);
    const now = new Date();
    const item = {
      value,
      version,
      expires: now.getTime() + (daysToSave ?? 1) * 24 * 60 * 60 * 1000,
    };
    this.setAtPath(`${path ? `${path}/${key}` : key}`, item);
  }

  /**
   * return the value from the localStorage or from the memory
   * @param key
   * @param path
   * @param validateTypeFn
   * @returns null if the item is not found or expired or the type is not valid
   * @example
   * ``` typescript
   * const cartToken = localStorageService.getItem<string>('cartToken', 'cart');
   * ```
   */
  getItem<T>(key: string, path?: string, validateTypeFn?: (item: unknown) => item is T): T | null {
    const item = getAtPath(this.terrificLiveDBObject, `${path ? `${path}/${key}` : key}`);
    if (!item) return null;
    if (item.expires < new Date().getTime()) {
      this.removeItem(key, `${path ? `${path}/${key}` : key}`);
      return null;
    }
    if (validateTypeFn && !validateTypeFn(item.value)) return null;
    return item.value as T;
  }
  /**
   * remove the item from the localStorage and from the memory
   * @param path
   */
  removeItem(key: string, path?: string) {
    this.setAtPath(`${path ? `${path}/${key}` : key}`, null);
  }

  constructor() {
    this.terrificLiveDBObject = JSON.parse(localStorage.getItem(ROOT_KEY) ?? '{}') as any;
    this.cleanExpiredItems(); // runs only once a day because the expiration is only once a day
    setInterval(() => this.cleanExpiredItems(), 24 * 60 * 60 * 1000);
  }

  private cleanExpiredItems() {
    const now = new Date().getTime();
    const validateTypeFn = (item: unknown): item is SavedData => {
      return (
        typeof item === 'object' &&
        item !== null &&
        typeof (item as SavedData).value !== 'undefined' &&
        typeof (item as SavedData).version === 'string' &&
        typeof (item as SavedData).expires === 'number'
      );
    };
    function* visitStack(
      obj: Record<string, JSONValue>
    ): Generator<{obj: Record<string, JSONValue>; key: string; val: SavedData}> {
      for (const [key, val] of Object.entries(obj ?? {})) {
        if (typeof val === 'object' && !validateTypeFn(val)) {
          yield* visitStack(val as Record<string, JSONValue>);
        } else if (validateTypeFn(val)) {
          yield {obj, key, val};
        } else {
          // its empty or not valid
          delete obj[key];
        }
      }
    }
    for (const {obj, key, val} of visitStack(this.terrificLiveDBObject)) {
      if (typeof val === 'object' && val.expires < now) {
        delete obj[key];
      }
    }

    this.setAtPath();
  }
}

function getAtPath(obj: Record<string, JSONValue>, path: string): SavedData | null {
  const pathArray = path.split('/').filter(Boolean);
  let currentObj: Record<string, JSONValue> | null = obj;
  for (const pathItem of pathArray) {
    if (!currentObj) return null;
    currentObj = currentObj[pathItem] as Record<string, JSONValue> | null;
  }
  return currentObj as SavedData;
}
function setAtPath(obj: Record<string, JSONValue>, path: string, value: SavedData | null) {
  const pathArray = path.split('/').filter(Boolean);
  let currentObj: Record<string, JSONValue> | null = obj ?? {};
  pathArray.forEach((pathItem, index) => {
    if (!currentObj) return;
    if (index === pathArray.length - 1) {
      currentObj[pathItem] = value;
    } else {
      currentObj[pathItem] = currentObj[pathItem] ?? {};
    }
    currentObj = currentObj[pathItem] as Record<string, JSONValue> | null;
  });
}
