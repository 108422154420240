<app-media-file
  [platformMediaCustomableName]="name"
  class="app-loader"
  mediaStyle="width: 100px; height: 100px;"
  ><ng-lottie
    containerClass="lottie-loader"
    width="100px"
    [options]="lottieLoaderOptions"
    class="app-loader"
  >
  </ng-lottie
></app-media-file>
<span class="sr-only" role="status">'Loader'</span>
