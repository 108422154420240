/* eslint-disable no-unused-vars */
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import type {Observable} from 'rxjs';
import type {
  DbProductModel as _DbProductModel,
  DbProductVariantModel,
} from '../../../../shared/db-models/product';

type DbProductModel = Omit<_DbProductModel, 'id'>;

export interface page<T> {
  limit?: number | null | undefined;
  nextPage?: number | string | null | undefined;
  page?: number | string | null | undefined;
  pages?: number | undefined;
  prevPage?: number | string | null | undefined;
  total?: number | null | undefined;
  items: T[];
}

/**
 * get list of all products from the store. can be limited by the platform.
 *
 * @param limit number
 * @param page number
 * @param search string
 * @param category string
 * @param sku string
 */
export interface QueryOptions {
  limit?: number | undefined;
  page?: string | number | null | undefined;
  search?: string;
  category?: string;
  sku?: string;
  storeUrl?: string;
}

export type Nullable<T> = {[K in keyof T]: T[K] | Nullable<T[K]> | null | undefined};

export abstract class GetCatalog<T> {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(protected fns: AngularFireFunctions) {}
  /**
   *
   * get list of all products from the store. can be limited by the platform.
   */
  abstract getProducts(): Observable<page<Nullable<T>>>;
  /**
   * get list of all products from the store. can be limited by the platform.
   */
  abstract getProducts(queryOptions: QueryOptions): Observable<page<Nullable<T>>>;

  /**
   * Get a single product
   */
  abstract getProduct(id: string): Observable<T>;

  /**
   * convert product model to terrific product model
   *
   * @param product T
   */
  abstract convertPlatformProductToTerrificProductModel(product: T): {
    product: DbProductModel;
    variants: DbProductVariantModel[];
  };

  /**
   * convert terrific product model to platform product model
   *
   * @param product SessionDataProductDTO
   */
  abstract convertTerrificProductToPlatformProductModel(product: {
    product: DbProductModel;
    variants: DbProductVariantModel[];
  }): T;

  /**
   * convert Platform Products To Terrific Product Model
   *
   * @param products
   */
  convertPlatformProductsToTerrificProductModel = (products: T[]) =>
    products.map((product) => this.convertPlatformProductToTerrificProductModel(product));

  /**
   * convert Terrific Products To Platform Product Model
   *
   * @param products SessionDataProductDTO
   */
  convertTerrificProductsToPlatformProductModel = (
    products: {product: DbProductModel; variants: DbProductVariantModel[]}[]
  ) => products.map((product) => this.convertTerrificProductToPlatformProductModel(product));
}

export class ProductNotFoundError extends Error {
  /** The stack of the error. */
  readonly stack?: string;

  constructor(catalogProvider: string, url: string, resultOfFetch: unknown = null) {
    super(`Product not found for url: ${url}`);
    this.name = 'ProductNotFoundError';
    this.stack = new Error().stack;
    this.message = `${catalogProvider} ~ Product not found for url: ${url}, resultOfFetch: ${safeStringify(
      resultOfFetch
    )}`;
  }
  // this practice copied from https://github.com/firebase/firebase-js-sdk/blob/master/packages/firestore/src/util/error.ts#L233
  // the comment there is:
  // HACK: We write a toString property directly because Error is not a real
  // class and so inheritance does not work correctly. We could alternatively
  // do the same "back-door inheritance" trick that FirebaseError does.
  public readonly toString = () => this.message;
}

function safeStringify(obj: unknown) {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    return obj;
  }
}
