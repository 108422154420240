<!-- checkmark icon -->
<img
  class="checkmark-circle"
  src="assets/images/checkmark-circle.svg"
  alt="Circle with check mark"
/>

<!-- Title -->
<h2 class="title">{{ 'REMINDER_CONFIRMED.REMINDER_CONFIRMED_TITLE' | translate }}</h2>

<!-- Text -->
<p>
  {{ 'REMINDER_CONFIRMED.CONFIRMATION_SENT_TO_YOU_EMAIL' | translate }}<br />
  {{ 'REMINDER_CONFIRMED.KEEP_YOU_UPDATED' | translate }}
</p>

<!-- Back button -->
<button
  class="btn-base btn-primary btn-extra-large btn-rounded btn-back"
  (click)="modal.dismiss()"
  type="submit"
>
  {{ 'REMINDER_CONFIRMED.OK_SEE_YOU' | translate }}
</button>
