export class CompareHelpers {
  public static compareNumber(a: number, b: number, isDescending: boolean = false): number {
    if (a < b) {
      if (isDescending) {
        return 1;
      } else {
        return -1;
      }
    }

    if (a > b) {
      if (isDescending) {
        return -1;
      } else {
        return 1;
      }
    }

    // must be equal
    return 0;
  }

  public static compareStrings(a: string, b: string, isDescending: boolean = false): number {
    let strA = '';
    if (a) {
      strA = a.toUpperCase(); // ignore upper and lowercase
    }

    let strB = '';
    if (b) {
      strB = b.toUpperCase(); // ignore upper and lowercase
    }

    if (strA < strB) {
      if (isDescending) {
        return 1;
      } else {
        return -1;
      }
    }

    if (strA > strB) {
      if (isDescending) {
        return -1;
      } else {
        return 1;
      }
    }

    // names must be equal
    return 0;
  }
}
