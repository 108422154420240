import {inject, Injectable} from '@angular/core';
import {FirebaseError} from '@angular/fire/app';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Router} from '@angular/router';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe-decorator';
import {
  asapScheduler,
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  of,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  retry,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  CartProductChange,
  MessagePurpose,
} from 'terrific-shared/ecommerce-platform-integration/sdk/messages';
import {DbCartModel, DbCartProductModel} from '../../../../shared/db-models/cart';
import {
  PaymentData,
  PaymentDataTransferModel,
  paymentDetailsForCart,
  paymentDetailsForSessionEntranceFee,
  PaymentResultModel,
} from '../../../../shared/dto-models/payments';
import {
  SessionDataCartDTO,
  SessionDataFullProductDTO,
  SessionDataProductVariantDTO,
} from '../../../../shared/dto-models/session-data';
import {FileHelpers} from '../helpers/file-helpers';
import {recursiveMapOperator} from '../helpers/recursive-map.rxjs';
import TimestampHelper from '../helpers/timestamp-helper';
import {LanguageService} from '../language.service';
import {autoLog} from '../logger/auto-log.decorator';
import {LogService} from '../logger/logger.service';
import {AnalyticsService} from './analytics.service';
import {AppService} from './app.service';
import {LazyServiceProvider} from './lazy-service-provider.service';
import {PaymentsService} from './payments.service';
import {PostMessengerService} from './sdk/post-messenger.service';
import {StateHolderService} from './state-holder.service';
import {StorageService} from './storage.service';
import {ProductNotFoundError} from '../interfaces/get-catalog';
import {ReferralUserIdService} from './referral-user-id.service';
import {DbOrderModel} from '../../../../shared/db-models/order';
import {ProductCustomProperties} from 'terrific-shared/db-models/product';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private fns = inject(AngularFireFunctions);
  private storage = inject(StorageService);
  private analytics = inject(AnalyticsService);
  private router = inject(Router);
  private payments = inject(PaymentsService);
  protected appService = inject(AppService);
  protected languageService = inject(LanguageService);
  protected stateService = inject(StateHolderService);
  protected lazyLoadService = inject(LazyServiceProvider);
  public logService = inject(LogService);
  private referralUserService = inject(ReferralUserIdService);
  protected postMessengerService = this.lazyLoadService.get(PostMessengerService);

  private firestore = inject(AngularFirestore);
  public isCartCurrentlyBeingUpdated: boolean;

  @AutoUnsubscribe()
  private activeCart$ = new BehaviorSubject<Readonly<SessionDataCartDTO> | undefined>(undefined);

  @AutoUnsubscribe()
  private liveActiveCart$ = this.activeCart$.pipe(
    distinctUntilChanged((a, b) => !!(a === b || (a && b && a.userCart.id === b.userCart.id))),
    shareReplay({refCount: false, bufferSize: 1, scheduler: asapScheduler}),
    switchMap((cart) => {
      if (!cart?.userCart.id) return this.activeCart$;
      this.logService.debug('update the cart liveActiveCart$');

      return combineLatest([
        this.userLiveCart(cart.userCart.id).pipe(startWith(cart)),
        this.activeCart$.pipe(startWith(cart)),
      ]).pipe(
        map(([firestoreDocResponse, functionsCartResponse]) => {
          const aTime = firestoreDocResponse?.userCart?.lastUpdateDate?.toMillis() || 0;
          const bTime = functionsCartResponse?.userCart?.lastUpdateDate?.toMillis() || 0;

          if (aTime >= bTime) {
            return firestoreDocResponse;
          }
          return functionsCartResponse;
        })
      );
    }),
    map((cart) => {
      this.logService.debug('update the cart activeCart$');
      if (cart && !cart.userCart.isOpen) {
        // if the cart been paid for, we need to clear the cart
        return {...cart, products: []};
      }

      return cart ? {...cart, products: this.sortCartProducts(cart.products)} : undefined;
    }),
    shareReplay({refCount: false, bufferSize: 1, scheduler: asapScheduler})
  );

  public sortCartProducts(cartProducts: DbCartProductModel[] | undefined): DbCartProductModel[] {
    if (cartProducts === undefined) {
      return [];
    }
    return cartProducts.sort((a, b) => {
      if (a.addDate.toMillis() < b.addDate.toMillis()) {
        return 1;
      }
      if (a.addDate.toMillis() > b.addDate.toMillis()) {
        return -1;
      }
      return 0;
    });
  }

  @autoLog('CartService ~ getCurrentSessionCart') public getCurrentSessionCart() {
    return this.liveActiveCart$;
  }

  public deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key)) return false;
      if (!this.deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  }

  @autoLog('CartService ~ addProductToCart')
  public addProductToCart(
    sessionId: string,
    product: SessionDataFullProductDTO,
    variant: SessionDataProductVariantDTO,
    quantity: number,
    customText: string | null = null,
    customProperties: ProductCustomProperties = {}
  ) {
    const cart = this.activeCart$.value;
    if (cart) {
      this.activeCart$.next(cart);
    }
    this.isCartCurrentlyBeingUpdated = true;
    console.debug(
      `[DEBUG-v2] addProductToCart: ${product.id}, ${
        variant.id
      }, ${quantity},pcov: ${JSON.stringify(product.customProperties)}, cov: ${customProperties}`,
      customProperties
    );
    return this.fns
      .httpsCallable<any, DbCartModel>('addProductToSessionCartV2')({
        sessionId,
        productId: product.id,
        variantId: variant.id,
        quantity,
        customText,
        customProperties,
      })
      .pipe(
        recursiveMapOperator(TimestampHelper.fixAllTimestampsOnValue),
        tap((newCart) => {
          console.debug(`[DEBUG-v2] addProductToCart.pipe: newCart: ${JSON.stringify(newCart)}`);
          this.activeCart$.next({
            userCart: newCart,
            products: newCart.products ?? [],
          });
          this.postMessengerService().postMessage({
            data: {
              product: newCart.products?.find((p) => {
                const equalIds = p.productId === product.id && p.variantId === variant.id;
                const equalCustomProperties =
                  Object.keys(customProperties).length > 0
                    ? this.deepEqual(p.customProperties, customProperties)
                    : true;
                return equalIds && equalCustomProperties;
              }),
              type: CartProductChange.add,
              prevCart: cart?.userCart,
              newCart: newCart,
            },
            messageType: MessagePurpose.CartChangeNotification,
          });
          this.isCartCurrentlyBeingUpdated = false;
        }),
        catchError((e) => {
          this.isCartCurrentlyBeingUpdated = false;
          if (e instanceof FirebaseError && e.message.includes('OUT_OF_STOCK')) {
            product.variants.find((_variant) => variant.id === _variant.id)!.isOutOfStock = true;
          }
          return this.languageService.translateError(e);
        })
      );
  }

  @autoLog('CartService ~ removeProductToCart')
  public removeProductToCart(cartId: string, cartProductId: string): Observable<DbCartModel> {
    const currentCart = this.activeCart$.value?.userCart;

    if (!cartId && currentCart?.id) {
      cartId = currentCart.id;
    }

    this.analytics.logEvent('remove_from_cart', {
      path: this.router.url,
      cart_Id: cartId,
      items: [
        {
          item_id: cartProductId,
        },
      ],
    });

    const cart = this.activeCart$.value;
    if (cart) {
      this.activeCart$.next(cart);
    }

    return this.fns
      .httpsCallable<any, DbCartModel>('removeProductFromCartV2')({cartId, cartProductId})
      .pipe(
        recursiveMapOperator(TimestampHelper.fixAllTimestampsOnValue),
        tap((newCart) => {
          this.activeCart$.next({
            userCart: newCart,
            products: newCart.products ?? [],
          });
          this.postMessengerService().postMessage({
            data: {
              product: cart?.products?.find((p) => p.id === cartProductId),
              type: CartProductChange.remove,
              prevCart: cart?.userCart,
              newCart: newCart,
            },
            messageType: MessagePurpose.CartChangeNotification,
          });
        }),
        catchError((e) => {
          if (e.code === 'functions/not-found') return EMPTY;
          return this.languageService.translateError(e);
        }),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  @autoLog('CartService ~ updateProductQuantityInCart')
  public updateProductQuantityInCart(
    cartId: string | undefined,
    cartProductId: string | undefined,
    quantity: number
  ): Observable<DbCartModel> {
    const currentCart = this.activeCart$.value;
    const product = currentCart?.products.find((p) => p.id === cartProductId);

    if (!cartId && currentCart?.userCart.id) {
      cartId = currentCart.userCart.id;
    } else if (!cartId && currentCart?.userCart.sessionId) {
      if (!product) {
        const err = new ProductNotFoundError('cartService', 'product not found on new api');
        return throwError(() => err);
      }
      return timer(1000).pipe(
        switchMap(() =>
          this.updateProductQuantityInCart(
            this.activeCart$.value?.userCart.id,
            product.id,
            quantity
          )
        )
      );
    }
    this.analytics.logEvent('add_to_cart', {
      path: this.router.url,
      cart_Id: cartId,
      items: [
        {
          item_id: cartProductId,
        },
      ],
    });

    return this.fns
      .httpsCallable<any, DbCartModel>('updateProductQuantityInCartV2')({
        cartId,
        cartProductId,
        quantity,
      })
      .pipe(
        recursiveMapOperator(TimestampHelper.fixAllTimestampsOnValue),
        tap((cart) => {
          this.activeCart$.next({
            userCart: cart,
            products: cart.products ?? [],
          });
          this.postMessengerService().postMessage({
            data: {
              product: cart.products?.find((p) => p.id === cartProductId) ?? product,
              type: quantity > 0 ? CartProductChange.updateUp : CartProductChange.updateDown,
              prevCart: currentCart?.userCart,
              newCart: cart,
            },
            messageType: MessagePurpose.CartChangeNotification,
          });
        }),
        catchError((e) => {
          const cart = this.activeCart$.value;
          if (cart) {
            this.activeCart$.next(cart);
          }
          return this.languageService.translateError(e);
        })
      );
  }

  public updateCartProductCustomValue(
    cartId: string,
    cartProductId: string,
    customValue: string
  ): Observable<boolean> {
    return this.fns
      .httpsCallable('updateCartProductCustomValueV2')({
        cartId,
        cartProductId,
        customValue,
      })
      .pipe(
        recursiveMapOperator(TimestampHelper.fixAllTimestampsOnValue),
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   *
   * @param cartId
   * @param paymentMethodId
   * @param isNewPaymentMethod
   * @param amountToPay
   * @param saveForFutureUse
   * @param shippingMethodId
   * @param shippingAddressId
   * @param billingAddressId
   * @param sessionId
   */
  @autoLog('CartService ~ payForCart')
  public payForCart(
    cartId: string,
    paymentMethodId: string | null,
    isNewPaymentMethod: boolean,
    amountToPay: number,
    saveForFutureUse: boolean,
    shippingMethodId: string | undefined,
    shippingAddressId: string,
    billingAddressId: string,
    sessionId: string,
    isAutoCheckout: boolean
  ): Observable<PaymentResultModel> {
    console.trace('payForCart');
    this.analytics.logEvent('purchase', {
      path: this.router.url,
      transaction_id: paymentMethodId,
      value: amountToPay,
      shipping: shippingAddressId,
      affiliation: cartId,
    });
    const cartDetails: paymentDetailsForCart = Object.assign(new paymentDetailsForCart(), {
      cartId,
      shippingMethodId,
      shippingAddressId,
      billingAddressId,
    });
    const paymentDetails: PaymentDataTransferModel = {
      paymentMethodId,
      isNewPaymentMethod,
      saveForFutureUse,
      amountToPay,
      paymentReason: 'payForCart',
      details: cartDetails,
      sessionId: sessionId,
      refererUserId: this.referralUserService.getReferralUser(sessionId),
    };
    const paymentData = new PaymentData(paymentDetails);
    return this.payments.pay(paymentData, !!isAutoCheckout).pipe(
      catchError((e) => this.languageService.translateError(e)),
      retry({count: 3, delay: 300, resetOnSuccess: true})
    );
  }

  getRedirectLinkForCartPayment(cartId: string) {
    this.analytics.logEvent('purchase', {
      path: this.router.url,
      transaction_id: cartId,
    });
    return this.payments.getRedirectLinkForCartPayment({cartId});
  }

  /**
   *
   * @param orderId
   * @param paymentId
   * @param intentId
   */
  public finalizeStripePayForCart(
    paymentId: string,
    intentId: string
  ): Observable<PaymentResultModel> {
    return this.fns
      .httpsCallable('finalizePayForCart')({paymentId, intentId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public checkPaymentStatusForCart(paymentId: string): Observable<PaymentResultModel> {
    return this.fns
      .httpsCallable('checkPaymentStatusForCart')({paymentId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public checkPaymentStatusForSessionFee(paymentId: string): Observable<PaymentResultModel> {
    return this.fns
      .httpsCallable('checkPaymentStatusForSessionFee')({paymentId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public payEntranceFeeForSession(
    sessionId: string,
    paymentMethodId: string | null,
    isNewPaymentMethod: boolean,
    amountToPay: number,
    saveForFutureUse: boolean
  ): Observable<PaymentResultModel> {
    const paymentDetails: PaymentDataTransferModel = {
      paymentMethodId,
      isNewPaymentMethod,
      saveForFutureUse,
      amountToPay,
      paymentReason: 'PayForSessionEntranceFee',
      details: new paymentDetailsForSessionEntranceFee(),
      sessionId: sessionId,
      billingAddressId: undefined,
      refererUserId: this.referralUserService.getReferralUser(sessionId),
    };
    const paymentData = new PaymentData(paymentDetails);
    return this.payments.pay(paymentData);
  }

  @autoLog('CartService ~ userLiveCart')
  private userLiveCart(cartId: string) {
    if (!cartId) return this.activeCart$;
    return this.firestore
      .doc<DbCartModel>(`carts/${cartId}`)
      .valueChanges()
      .pipe(
        map((cart) => {
          if (!cart) return undefined;
          cart.id = cartId;
          return {
            userCart: cart,
            products: cart.products,
          } as Readonly<SessionDataCartDTO>;
        }),
        switchMap((cart) => (cart ? of(cart) : this.activeCart$)),
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public getUserCart(
    cart: SessionDataCartDTO | undefined
  ): Observable<undefined | SessionDataCartDTO> {
    this.activeCart$.next(cart);
    return this.liveActiveCart$;
  }

  public finalizeStripePayForSessionEntranceFee(
    paymentId: string,
    intentId: string
  ): Observable<PaymentResultModel> {
    return this.fns
      .httpsCallable('finalizePayForSessionEntranceFee')({paymentId, intentId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public uploadCartProductCustomImage(
    userId: string,
    storeId: string,
    sessionId: string,
    cartProductId: string,
    file: File
  ): Observable<string> {
    const filePath = `customProducts/stores/${storeId}/session/${sessionId}/cartProduct/${cartProductId}/${userId}.${FileHelpers.getFileExtension(
      file
    ).toLowerCase()}`;
    return this.storage.uploadFileToStorage(filePath, file, '.jpg,.png,.jpeg', 2);
  }

  public deleteCustomImageFromStorage(
    userId: string,
    storeId: string,
    sessionId: string,
    cartProductId: string,
    imageUrl: string
  ) {
    const imageExtension = FileHelpers.getImageExtensionFromUrl(imageUrl);
    if (imageExtension) {
      const pathToDelete = `customProducts/stores/${storeId}/session/${sessionId}/cartProduct/${cartProductId}/${userId}.${imageExtension}`;
      return this.storage.deleteImage(pathToDelete);
    }
  }

  public emptyCartAfterCheckout(order?: DbOrderModel) {
    if (!order) return throwError(() => new Error('order is undefined'));
    const {cartId, storeId} = order;
    return this.fns
      .httpsCallable('emptyingCartAfterCheckoutV2')({cartId, storeId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      )
      .subscribe();
  }
}
