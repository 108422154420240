/**
 * this decorators are used to cache the result of a function
 */
import hashIt from 'hash-it';
import {Observable, asyncScheduler, shareReplay} from 'rxjs';

export const CacheAble = () => {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalDef = descriptor.value;

    /**
     * this is a unique key for each function and its arguments
     */
    const cacheKey = Symbol.for(
      hashIt({
        name: `${(target.prototype ?? target).constructor.name}__cache_${String(propertyKey)}`,
        args: hashIt(originalDef),
      }).toString()
    );

    target[cacheKey] = new Map();

    descriptor.value = function (...args: any[]) {
      const key = hashIt(args);
      if (target[cacheKey].has(key)) {
        return target[cacheKey].get(key);
      }
      const result = originalDef.apply(this, args);
      target[cacheKey].set(key, result);
      return result;
    };

    return descriptor;
  };
};

/**
 * this decorator are used to cache the result of a function that returns an observable
 */
export const CacheAbleShare = () => {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalDef = descriptor.value;

    /**
     * this is a unique key for each function and its arguments
     */
    const cacheKey = Symbol.for(
      hashIt({
        name: `${(target.prototype ?? target).constructor.name}__cache_${String(propertyKey)}`,
        args: hashIt(originalDef),
      }).toString()
    );

    target[cacheKey] = new Map();

    descriptor.value = function (...args: any[]) {
      const key = hashIt(args);
      if (!target[cacheKey].has(key)) {
        let result = originalDef.apply(this, args);
        if (isObservable(result)) {
          result = result.pipe(
            shareReplay({refCount: true, bufferSize: 1, scheduler: asyncScheduler})
          );
        }
        target[cacheKey].set(key, result);
      }
      const result = target[cacheKey].get(key);

      return result;
    };

    return descriptor;
  };
};

function isObservable(obj: any): obj is Observable<unknown> {
  // The !! is to ensure that this publicly exposed function returns
  // `false` if something like `null` or `0` is passed.
  return (
    !!obj &&
    (obj instanceof Observable ||
      (typeof obj.lift === 'function' && typeof obj.subscribe === 'function'))
  );
}
