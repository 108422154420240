import {Injectable, Injector, inject} from '@angular/core';
import {hasNew} from '../../../../shared/utilities/type-guards';
import {LogService} from '../logger/logger.service';
import {isObservable} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor = new (...args: any[]) => any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Fn = (...args: any[]) => any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PromiseFn = (...args: any[]) => Promise<any>;
type lib = Constructor | Fn | PromiseFn;

@Injectable({providedIn: 'root'})
export class LazyServiceProvider {
  private injector = inject(Injector);
  private logService = inject(LogService).logWithMetadata({serviceName: 'LazyServiceProvider'});

  /**
   * This method returns a singleton instance of a service. If the service has a constructor (i.e., it's a class),
   * it will try to get the instance from Angular's injector. If the service is not registered in the injector,
   * or it's a simple value or function, it will create a new instance.
   *
   * @param service - The service to get an instance of.
   * @param afterInitFn - A function that will be called after the service is initialized.
   * It can be used to perform additional setup.
   * Make sure to return a promise | observable if the setup is asynchronous.
   * @returns - A function that returns the singleton instance of the service.
   */
  public get<T extends lib>(
    service: T,
    afterInitFn?: (
      instance: T extends Constructor ? InstanceType<T> : ReturnType<Exclude<T, Constructor>>
    ) => unknown | Promise<unknown>
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let instance: T extends Constructor ? InstanceType<T> : ReturnType<Exclude<T, Constructor>>;
    const injectFn = () => {
      // promise or mistake
      if (!hasNew(service)) return service();

      try {
        return this.injector.get(service);
      } catch (_) {
        return new service();
      }
    };

    return () => {
      if (instance) return instance;

      const isClassOrFn = typeof service === 'function' || typeof service === 'object';

      const console = this.logService.logWithMetadata({
        functionName: 'get',
        resource: isClassOrFn && service && 'name' in service ? service.name : undefined,
      });

      console.debug(`Getting service from the injector.`);
      instance = injectFn();

      if (!instance) {
        console.error(`Service instance not found.`);
        throw new Error(`Service ${service} is not registered in the injector.`);
      }

      if (afterInitFn) {
        // should be called only once per "service", even if the service was injected multiple times
        // the service that inject with a call to afterInitFn need to verify there is no other services
        // that inject the same service with afterInitFn
        // in the case of multiple services that inject the same service with afterInitFn
        // only the first one that called the service will run the afterInitFn
        // and the rest will just get the instance and a console warning will be printed
        this.initializeService(instance, afterInitFn);
      }

      console.info(`Service initialized successfully.`);
      return instance;
    };
  }

  private initializedServices: WeakSet<lib> = new WeakSet();
  private initializeService<T extends lib>(
    service: T,
    afterInitFn: (instance: T) => unknown | Promise<unknown>
  ) {
    const console = this.logService.logWithMetadata({
      functionName: 'initializeService',
      resource: service && 'name' in service ? service.name : undefined,
    });

    if (this.initializedServices.has(service)) {
      console.warn(`Service already initialized.`);
      return;
    }

    // added to here to handle the case of after init been a promise
    this.initializedServices.add(service);
    console.debug(`Running afterInitFn for service instance.`);

    const onSuccessMessage = 'Service afterInitFn ran successfully.';
    const onErrorMessage = 'Service afterInitFn failed to run:';

    // called here to make sure we call it synchronously
    try {
      const res = afterInitFn(service);
      const type = typeof res;
      const isFnOrObj = type === 'function' || type === 'object';

      if (isFnOrObj && isObservable(res)) {
        res.subscribe({
          next: () => console.info(onSuccessMessage),
          error: (error) => console.error(onErrorMessage, error),
        });
        return;
      }

      if (res) {
        // convert to promise only to verify we are logging after init finished
        Promise.resolve(res)
          .then((res) => console.info(onSuccessMessage, res))
          .catch((error) => console.error(onErrorMessage, error));
        return;
      }

      console.log(onSuccessMessage);
    } catch (error) {
      console.error(onErrorMessage, error);
    }
  }
  /**
   * This method is similar to the get method,
   * but it's designed to work with services that are loaded asynchronously
   * (e.g., using dynamic imports).
   * It takes a function that returns a promise of a service,
   * waits for the service to be loaded, and then gets a singleton instance of the service / lib.
   */
  public import<T>(
    service: () => Promise<T>,
    afterInitFn?: (instance: T) => unknown | Promise<unknown>
  ) {
    const awaitedAfterInitFn = afterInitFn
      ? (instance: Promise<T>) =>
          Promise.resolve(instance).then((instance) => afterInitFn(instance))
      : undefined;
    // avoid immediate call to the import
    // this will make sure only when the caller of import will try to use the 'service'
    // the service will be imported
    return () => this.get(service, awaitedAfterInitFn)();
  }
}
