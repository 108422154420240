import {inject, Injectable} from '@angular/core';
import {AngularFirestore, QuerySnapshot} from '@angular/fire/compat/firestore';
import {defer, from, Observable, of, switchMap} from 'rxjs';
import {retry} from 'rxjs/operators';
import {DbSharedLinkModel, SharedLinkTarget} from 'terrific-shared/db-models/links';
import TimestampHelper from '../helpers/timestamp-helper';
import {LogService} from '../logger/logger.service';
import {UsersService} from './users.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicLinksService {
  private usersService = inject(UsersService);
  private firestore = inject(AngularFirestore);
  private logService = inject(LogService);

  public createDynamicLink(path: string, target: SharedLinkTarget) {
    const userId = this.usersService.connectedUserSync.uid;
    this.logService.debug(`createDynamicLink, userId: ${userId}, target:`, target);
    return this.firestore
      .collection<DbSharedLinkModel>('links', (ref) =>
        ref
          .where('refererUserId', '==', userId)
          .where('path', '==', path)
          .where('target.type', '==', target.type)
          .where('target.id', '==', target.id)
      )
      .get()
      .pipe(
        switchMap((links) => {
          return links.empty
            ? this.createNewLink(userId, path, target)
            : of(this.takeEarliest(links));
        })
      );
  }

  public buildFullUrl(link: DbSharedLinkModel, origin: string) {
    return new URL(`link/${link.id}`, origin).href;
  }

  public createNewLink(
    userId: string,
    path: string,
    target: SharedLinkTarget
  ): Observable<DbSharedLinkModel> {
    this.logService.debug(
      `create new link by user '${userId}' with path '${path}' for target`,
      target
    );
    return defer(async () => {
      const link: DbSharedLinkModel = {
        id: this.generateLinkId(),
        refererUserId: userId,
        path,
        target,
        createAt: TimestampHelper.getCurrentDateTimestamp(),
      };
      await this.firestore.doc<DbSharedLinkModel>(`links/${link.id}`).set(link);
      return link;
    }).pipe(retry(5));
  }

  private takeEarliest(links: QuerySnapshot<DbSharedLinkModel>) {
    return links.docs
      .sort((a, b) => a.data().createAt.toMillis() - b.data().createAt.toMillis())[0]
      .data();
  }

  private generateLinkId() {
    return this.firestore.createId();
  }

  public getDynamicLinks(storeId: string, sessionId: string): Observable<DbSharedLinkModel[]> {
    return this.firestore
      .collection<DbSharedLinkModel>('links', (ref) =>
        ref
          .where('target.storeId', '==', storeId)
          .where('target.sessionId', '==', sessionId)
          .where('target.type', '==', 'session')
      )
      .valueChanges();
  }

  public updateDescription(linkId: string, description: string) {
    return from(
      this.firestore
        .collection<DbSharedLinkModel>('links')
        .doc(linkId)
        .update({description, updateAt: TimestampHelper.getCurrentDateTimestamp()})
    );
  }

  public deleteDynamicLink(id: string) {
    return from(
      this.firestore.collection<DbSharedLinkModel>('links').doc(id).update({
        isDeleted: true,
      })
    );
  }
}
