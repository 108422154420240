export function formatCurrency(
  value: number | undefined,
  currency: string | undefined,
  showUndefinedAsNumber = true,
  isNarrow = false
): string {
  if (value === undefined)
    return showUndefinedAsNumber ? formatCurrency(0, currency, false, isNarrow) : '';

  const currencyToUse = currency ?? 'USD';

  let options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currencyToUse.toUpperCase(),
  };

  if (isNarrow) {
    options = {
      ...options,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 0,
    };
  }

  return new Intl.NumberFormat('en-US', options).format(value);
}
