import {PickByType} from './type-helpers';

export class MathHelpers {
  public static roundToTwoDecimalPoints(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  public static roundPercentagesInObjectArray<T>(
    arr: T[],
    prop: keyof PickByType<T, number>,
    target: number = 100
  ): T[] {
    const off = target - arr.reduce((acc, x) => acc + Math.round(x[prop] as number), 0);

    return arr
      .sort((x) => Math.round(x[prop] as number) - (x[prop] as number))
      .map((x, i) => {
        x[prop] = (Math.round(x[prop] as number) +
          (off > i ? 1 : 0) -
          (i >= arr.length + off ? 1 : 0)) as any;
        return x;
      });
  }
}
