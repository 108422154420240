export const generalVars = {
  typesenseConfig: {
    'nodes': [
      {
        'host': 'jl5y62qruwmda0hop-1.a1.typesense.net',
        'port': 443,
        'protocol': 'https',
      },
    ],
    'apiKey': 'UtVY4gE1f2XDaqLL3JJcobNXTpJg6Zcb',
    'connectionTimeoutSeconds': 2,
  },
  cspGuardUrl: 'https://us-central1-terrific-live.cloudfunctions.net/cspGuardV2',
};
