import {inject, Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {catchError, map, Observable, of} from 'rxjs';
import {
  DbSessionPollModel,
  DbSessionPollUserModel,
  PollStatus,
} from '../../../../shared/db-models/session';
import TimestampHelper from '../helpers/timestamp-helper';

@Injectable({
  providedIn: 'root',
})
export class PollsService {
  private firestore = inject(AngularFirestore);

  public getSessionPolls(sessionId: string): Observable<DbSessionPollModel[]> {
    return (
      this.firestore
        .collection<DbSessionPollModel>(`sessions/${sessionId}/sessionPolls`, (ref) =>
          ref
            .where('status', 'in', [PollStatus.Inactive, PollStatus.Active, PollStatus.Locked])
            .orderBy('createDate', 'asc')
        )
        .valueChanges({idField: 'id'}) ?? []
    );
  }

  public getSessionPollUser(
    sessionId: string,
    pollId: string,
    userId: string
  ): Observable<DbSessionPollUserModel | object> {
    return this.firestore
      .collection<DbSessionPollUserModel>(`sessions/${sessionId}/sessionPollUsers`, (ref) =>
        ref.where('pollId', '==', pollId).where('userId', '==', userId).limit(1)
      )
      .valueChanges()
      .pipe(
        map((polls: DbSessionPollUserModel[]): DbSessionPollUserModel | object => {
          return polls.length ? polls[0] : {};
        }),
        catchError(() => {
          return of({}); // In case collection is not exists
        })
      );
  }

  public createSessionPollUser(sessionId: string, sessionPollUser: DbSessionPollUserModel): void {
    this.firestore.firestore
      .doc(`sessions/${sessionId}/sessionPollUsers/${sessionPollUser.id}`)
      .set(sessionPollUser);
  }

  public activateSessionPoll(sessionId: string, sessionPollId: string): void {
    this.firestore.firestore.doc(`sessions/${sessionId}/sessionPolls/${sessionPollId}`).update({
      status: PollStatus.Active,
      activationDate: TimestampHelper.getCurrentDateTimestamp(),
    });
  }

  public deactivateSessionPoll(sessionId: string, sessionPollId: string): void {
    this.firestore.doc(`sessions/${sessionId}/sessionPolls/${sessionPollId}`).update({
      status: PollStatus.Locked,
    });
  }
}
