import {Component, Input} from '@angular/core';
export type ButtonType = 'checkbox';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  standalone: true,
})
export class CheckBoxComponent {
  @Input()
  checked = true;

  @Input()
  disabled: boolean;
}
