import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap'; // eslint-disable-line
import {ToastrService} from 'ngx-toastr';
import {finalize, Subject, take, takeUntil, tap} from 'rxjs';
import {ConnectedUserModel} from 'src/app/interfaces/users-models';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {NavigateService} from 'src/app/services/navigate.service';
import {StoresService} from 'src/app/services/stores.service';
import {UsersService} from 'src/app/services/users.service';
import {ReminderOptions, SendReminderData} from '../../../../../../shared/db-models/store';
import regex from '../../../helpers/regex';
import {ReminderConfirmedModalComponent} from '../../../modals/reminder-confirmed-modal/reminder-confirmed-modal.component';
import {SessionFacade} from '../../shared/session-facade.service';
import {SessionService} from '../../shared/session.service';
import {AppService} from 'src/app/services/app.service';

@Component({
  selector: 'app-toggle-reminder-modal',
  templateUrl: './toggle-reminder-modal.component.html',
  styleUrls: ['./toggle-reminder-modal.component.scss'],
})
export class ToggleReminderModalComponent implements OnInit, OnDestroy {
  private modalService = inject(NgbModal);
  private toastr = inject(ToastrService);
  protected modal = inject(NgbActiveModal);
  private sessionsService = inject(SessionService);
  public storesService = inject(StoresService);
  public usersService = inject(UsersService);
  public sessionFacade = inject(SessionFacade);
  public navigateService = inject(NavigateService);
  public analytics = inject(AnalyticsService);
  public appService = inject(AppService);
  isLoading = false;
  public isSubmitLoading = false;
  public reminderOptions: ReminderOptions | undefined;

  @Input() sessionId: string;
  @Input() prevRemindedState: boolean;

  public PAT_EMAIL = regex.EMAIL_REGEX;
  public PAT_PHONE = regex.PHONE_REGEX;
  public PAT_NAME = regex.NAME_REGEX;

  public user: ConnectedUserModel;

  private destroyed$ = new Subject();

  reminderData: SendReminderData = {};

  ngOnInit() {
    this.getStoreReminderOptions();
    this.initializeWithCurrentUserData();
    this.appService.isMobile.pipe(takeUntil(this.destroyed$)).subscribe((isMobile: boolean) => {
      const modalRef = document.querySelector('.toggle-reminder-modal');
      if (modalRef) {
        if (isMobile) {
          modalRef.classList.add('mobile');
        } else {
          modalRef.classList.remove('mobile');
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getStoreReminderOptions() {
    this.storesService.activeStore$.pipe(take(1)).subscribe((store) => {
      this.reminderOptions = store?.fullData.reminderOptions;
    });
  }

  private initializeWithCurrentUserData() {
    this.reminderData.name = this.usersService.connectedUserSync.displayName;
    this.reminderData.email = this.usersService.connectedUserSync.email;
    this.reminderData.phone = this.usersService.connectedUserSync.phone;
  }

  public toggleReminder() {
    this.isSubmitLoading = true;
    this.sessionFacade.trackUserActivity$.next(false);
    this.sessionsService
      .requestOrRemoveSessionReminder(this.sessionId, this.reminderData)
      .pipe(
        finalize(() => {
          this.isSubmitLoading = false;
        }),
        take(1),
        tap(() => this.analytics.logEvent('subscribed_to_reminder'))
      )
      .subscribe({
        next: (result) => {
          if (result === 'ok') {
            this.showReminderConfirmationModal();
            this.modal.close(true);
          }
        },
        error: (e) => {
          this.toastr.error(e instanceof Error ? e.message : 'Failed to register to the session');
          this.modal.close(false);
        },
      });
  }

  private showReminderConfirmationModal() {
    return this.modalService.open(ReminderConfirmedModalComponent, {
      windowClass: 'reminder-confirmed-modal',
      centered: true,
    });
  }
}
