import {
  DocumentData,
  DocumentReference,
  Primitive,
  Query,
  QuerySnapshot,
} from 'firebase-admin/firestore';
import {EventName, PossibleSessionEventsName} from '../db-models/session-events';
import {DbSessionModel} from '../db-models/session';

export enum SessionState {
  'notStarted' = 'notStarted',
  'broadcasting' = 'broadcasting',
  'paused' = 'paused',
  'playingPromo' = 'playingPromo',
  'endingPromo' = 'endingPromo',
  'ended' = 'ended',
}

export enum AnalyticsView {
  Upcoming,
  Live,
  PostSession,
  Total,
}

// TODO: rename to getAnalyticsView
export function getSessionStateSimplified(
  state: SessionState | undefined,
  session?: Pick<DbSessionModel, 'hasEnded' | 'startTime'>
): AnalyticsView {
  if (session?.hasEnded) return AnalyticsView.PostSession;
  if (session && session?.startTime.toMillis() > Date.now()) return AnalyticsView.Upcoming;
  switch (state) {
    case SessionState.broadcasting:
    case SessionState.paused:
    case SessionState.playingPromo:
    case SessionState.endingPromo:
      return AnalyticsView.Live;
    case SessionState.ended:
      return AnalyticsView.PostSession;
    case SessionState.notStarted:
    default:
      return AnalyticsView.Upcoming;
  }
}

export enum SessionEventCounter {
  likes = 'likes',
  shares = 'shares',
  shareUsages = 'shareUsages',
  orders = 'orders',
  totalSaleValue = 'totalSaleValue',
  totalItemsSold = 'totalItemsSold',
  openCarts = 'openCarts',
  totalItemsInCarts = 'totalItemsInCarts',
  eachItemCountInCarts = 'eachItemCountInCarts',
}
export enum DistinctSessionEventCounter {
  languages = 'languages',
  cities = 'cities',
  engagement = 'engagementUsersCount',
  sharers = 'sharers',
  uniqueShareUsages = 'uniqueShareUsages',
  itemSold = 'itemSold',
  itemCountInCarts = 'itemCountInCarts',
  totalViewers = 'totalViewers',
  currentViewers = 'currentViewers',
  usersProceededToCheckout = 'usersProceededToCheckout',
  cartsProceededToCheckout = 'cartsProceededToCheckout',
  itemsProceededToCheckout = 'itemsProceededToCheckout',
  cartsOpened = 'cartsOpened',
}

export type AggregateQuery = ReturnType<Query<any>['count']>;
export type AggregateQuerySnapshot = Awaited<ReturnType<AggregateQuery['get']>>;

type _SnapshotJobReq = {
  queryToRunAfterTheDistrictDocUpdated: FirebaseFirestore.DocumentReference<FirebaseFirestore.DocumentData>;
  writeResultOfKeyToValInto: DocumentReference<any>[];
  transformerForQueryResIntoUpdateFieldsPairs: (
    queryRes: FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData>
  ) => FieldValuePairsArray | undefined;
};

type _QueryJobReq = {
  queryToRunAfterTheDistrictDocUpdated: Query<DocumentData>;
  writeResultOfKeyToValInto: DocumentReference<any>[];
  transformerForQueryResIntoUpdateFieldsPairs: (
    queryRes: QuerySnapshot<DocumentData>
  ) => FieldValuePairsArray | undefined;
};
type _AggregateQueryJobReq = {
  queryToRunAfterTheDistrictDocUpdated: AggregateQuery;
  writeResultOfKeyToValInto: DocumentReference<any>[];
  transformerForQueryResIntoUpdateFieldsPairs: (
    queryRes: AggregateQuerySnapshot
  ) => FieldValuePairsArray | undefined;
};
export type QueryJobReq = _QueryJobReq | _AggregateQueryJobReq | _SnapshotJobReq;

export type QueryRes<T extends QueryJobReq> = Parameters<
  T['transformerForQueryResIntoUpdateFieldsPairs']
>[0];

export type FieldValuePairsArray = [FieldPath, FieldValue, Map<FieldPath, FieldValue>?];

export type SimpleCounterName =
  | SessionEventCounter
  | DistinctSessionEventCounter
  | keyof typeof EventName
  | 'maxViewers';

export type FieldPath =
  | 'maxViewers'
  | `${
      | SessionEventCounter
      | DistinctSessionEventCounter
      | CollectionName
      | PossibleSessionEventsName
      | 'value'
      | 'data'}`
  | `${SessionEventCounter | DistinctSessionEventCounter | CollectionName}.${string}`
  | `${SessionEventCounter | DistinctSessionEventCounter | CollectionName}.${string}`
  | `${SessionState}.${
      | SessionEventCounter
      | DistinctSessionEventCounter
      | CollectionName}.${string}`;

export type DistinctCollectionName = DistinctSessionEventCounter | `${DistinctSessionEventCounter}`;

export type CollectionName =
  | `${SessionEventCounter}`
  | SessionEventCounter
  | DistinctCollectionName;

export type CollectionRelativePath = string;

export type DocRelativePath = `${CollectionRelativePath}/${string}`;

export type FieldValue = Primitive | DocumentData;

export type CounterData = [FieldPath, FieldValue];

/**
 * Data object representing a distinct counter.
 * @property {DocRelativePath} sourceDocPath - The relative path of the source document.
 * @property {FieldPath} fieldPathToSaveValueIntoInsideTheSourceDoc - The field path to save the value into inside the source document.
 * @property {FieldValue} valueForTheSourceDocInFieldPath - The field value for the source document in the field path.
 * @property {JobRequestFactoryFromDocRef} jobRequestFactoryFromDocumentRef - The job request factory from the document reference.
 */
export type DistinctCounterData = {
  saveEventFieldsIntoDocPath: DocRelativePath;
  fieldPathToSaveValueIntoInsideTheSourceDoc: FieldPath;
  valueForTheSourceDocInFieldPath: FieldValue;
};

export type JobRequestFactoryFromDocRef = (q: DocumentReference) => QueryJobReq | QueryJobReq[];
