export interface SessionVideoDurationReport {
  sessionId: string;
  seatId: string;
  videoDuration: number;
}

export class SessionVideoDurationTracker {
  private sessionId: string | null = null;
  private seatId: string | null = null;
  private prevVideoCheckpointTs: number | null = null;

  public report(): SessionVideoDurationReport | null {
    let report: SessionVideoDurationReport | null = null;
    if (this.sessionId === null || this.seatId === null) {
      report = null;
    } else if (!this.prevVideoCheckpointTs) {
      report = {
        sessionId: this.sessionId,
        seatId: this.seatId,
        videoDuration: 0,
      };
    } else {
      const prev = this.prevVideoCheckpointTs;
      const now = Date.now();
      this.prevVideoCheckpointTs = now;
      report = {
        sessionId: this.sessionId,
        seatId: this.seatId,
        videoDuration: (now - prev) / 1000,
      };
    }
    console.debug(`VideoDurationTracker.report:`, report);
    return report;
  }

  public switch(sessionId: string, seatId: string, isVideoPlaying: boolean) {
    console.debug(
      `VideoDurationTracker.switch(${sessionId},${seatId},${isVideoPlaying}), current:`,
      {
        sessionId: this.sessionId,
        seatId: this.seatId,
        prevVideoCheckpointTs: this.prevVideoCheckpointTs,
      }
    );
    const report = this.report();
    this.sessionId = sessionId;
    this.seatId = seatId;
    this.prevVideoCheckpointTs = isVideoPlaying ? Date.now() : null;
    return report;
  }
}
