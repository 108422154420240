import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import type {
  PlatformMediaFile,
  PlatformMediaCustomableName,
} from '../../../../../shared/db-models/media';
import {AnimationOptions, LottieModule} from 'ngx-lottie';
import {from, map, Observable, of, retry, switchMap, tap} from 'rxjs';
import {environment} from 'src/environments/environment';
import {CommonModule} from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, LottieModule],
  selector: 'app-media-file',
  template: ` <ng-container *ngIf="docData$ | async as doc; else elseBlock">
      <ng-container *ngIf="this.fetchFile$ | async as options">
        <ng-lottie
          *ngIf="doc.mediaKind.includes('json')"
          [style]="mediaStyle"
          [ngClass]="mediaClasses"
          [options]="lottieOptions"
        >
        </ng-lottie>
      </ng-container>
      <video
        *ngIf="doc.mediaKind.includes('video')"
        [muted]="'muted'"
        autoplay
        loop
        muted
        playsinline
        [style]="mediaStyle"
        [ngClass]="mediaClasses"
      >
        <source [src]="doc.url" [type]="doc.mediaKind" />
      </video>
      <img
        *ngIf="doc.mediaKind.includes('image')"
        [style]="mediaStyle"
        [src]="doc.url"
        [ngClass]="mediaClasses"
        alt=""
      />
      <ng-content
        *ngIf="
          showDefault &&
          !doc.mediaKind.includes('json') &&
          !doc.mediaKind.includes('video') &&
          !doc.mediaKind.includes('image')
        "
      ></ng-content>
    </ng-container>
    <ng-template #elseBlock>
      <ng-content *ngIf="showDefault"></ng-content>
    </ng-template>`,
  styleUrls: ['./media-file.component.scss'],
})
export class MediaFileComponent implements OnInit {
  private _fs = inject(AngularFirestore);
  @Input() public showDefault = environment.client.clientType === 'terrific';

  @Input() platformMediaCustomableName: PlatformMediaCustomableName;
  @Input() mediaStyle: string | Record<string, string>;
  @Input() mediaClasses: string | Record<string, string> | any[];

  @Output() mediaFileLoaded = new EventEmitter<PlatformMediaFile | undefined>();
  public docData$: Observable<PlatformMediaFile | undefined>;
  public fetchFile$: Observable<string>;

  public lottieOptions: AnimationOptions;

  ngOnInit() {
    if (!this.platformMediaCustomableName) return;
    const _doc = this._fs.doc<PlatformMediaFile>('media/' + this.platformMediaCustomableName);
    const docData$ = _doc.valueChanges().pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
    this.docData$ = docData$;
    this.fetchFile$ = docData$.pipe(
      switchMap((data) => {
        if (data?.mediaKind.includes('json')) {
          return from(fetch(data.url).then((res) => res.json()) as Promise<JSON | null>);
        }
        return of(null);
      }),
      tap((data) => (this.lottieOptions = {animationData: data})),
      map((data) => JSON.stringify(data))
    );
  }
}
